Vue.component('header-component', {
    data: function () {
        return {
          flag: false
        }
    },
    methods:{
        toggle: function(){
            this.flag = !this.flag;
        }	
    },
    template:
    `<header id="header">
        <div class="display">
            <div class="meta">
                <h1 class="meta__h1">商業施設の総合プランナー、アパート、マンション、ビル、注文住宅、外構工事、建築工事、土木工事、不動産事業</h1>
                <figure class="meta__logo">
                    <img src="img/logo.png" alt="藤旺建設株式会社" />
                    <figcaption>総合建設・不動産コンサルタント<br>一級建築士事務所</figcaption>
                </figure>
                <a href="tel:0432915084" class="tel"><img src="img/tel_icon_2x.png" alt="0432915084" /></a>
            </div>
        </div>
        <nav class="navigation">
            <ul class="display">
                <li><a href="index.html" data-eng="HOME">ホーム</a></li>
                <li><a href="construction.html" data-eng="CONSTRUCTION">施工実績</a></li>
                <li><a href="land.html" data-eng="LAND">土地活用</a></li>
                <li><a href="about.html" data-eng="ABOUT">会社概要</a></li>
                <li><a href="contact.html" data-eng="CONTACT">お問い合わせ</a></li>
            </ul>
        </nav>
        <nav class="navigation-sp" @click="toggle()" :class="{ 'addclose' : flag}">
            <transition name="fade">
                <ul class="display" v-if="flag">
                    <li><a href="index.html" data-eng="HOME">ホーム</a></li>
                    <li><a href="construction.html" data-eng="CONSTRUCTION">施工実績</a></li>
                    <li><a href="land.html" data-eng="LAND">土地活用</a></li>
                    <li><a href="about.html" data-eng="ABOUT">会社概要</a></li>
                    <li><a href="contact.html" data-eng="CONTACT">お問い合わせ</a></li>
                </ul>
            </transition>
        </nav>
    </header>`
})

const vue_container = new Vue({
    el: "#wrap",
    data: {
        news : []
    },
    created: function(){
        if(document.getElementById('news-box-patched')){
            axios.get('news.json')
            .then(function(response) {
                vue_container.news = response.data;
            })
            .catch(function(error) {
                console.log(error);
            });
        }
    }
});